import React, { useEffect, useRef, useState } from 'react';
import ProLink from '../../shared/ProLink/ProLink';
import { miniGazelle, dataLayer } from '../../../helpers';

export default function NavigationMobile({ nav }) {
    const [menu, setMenu] = useState(nav);
    const [previousMenu, setPreviousMenu] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('');
    const menuListRef = useRef(null);
    console.log({ nav }, 'navigation data')

    useEffect(() => {
        nav.menuItems?.map(item => {
            item.level = 1;

            item.menuItems.forEach(subItem => {
                subItem.level = 2;

                subItem.menuItems.forEach(lowItem => {
                    lowItem.level = 3;
                });
            });
        });
    }, []);

    const changeMenu = async item => {
        await animate();

        setPreviousMenu(menu);
        setMenu({
            ...item,
        });
    };

    const goBack = async (reset = false) => {
        await animate();
        if (reset) {
            return setMenu(nav);
        }
        setMenu(previousMenu);
    };

    const animate = async () => {
        return new Promise(resolve => {
            const ref = menuListRef.current;

            ref.classList.remove('animate-in');
            ref.classList.add('animate-out');

            setTimeout(() => {
                ref.classList.remove('animate-out');
                ref.classList.add('animate-in');

                resolve();
            }, 100);
        });
    };

    const handleItemClick = (e, menuItemName, subItemName, menuItemLink) => {
        const formattedMenuItemName = menuItemName.toLowerCase().replace(/\s+/g, '-');
        const formattedSubItemName = subItemName.toLowerCase().replace(/\s+/g, '-');

        const typeMap = {
            'professional-products': miniGazelle.label('products'),
            solutions: miniGazelle.label('solutions'),
            'where-to-buy': miniGazelle.label('wheretobuy'),
            support: miniGazelle.label('support'),
            'contact-us': miniGazelle.label('contactus'),
            promotions: miniGazelle.label('promotions'),
            'view-all': miniGazelle.label('viewAll'),
        };

        const formattedType = typeMap[formattedMenuItemName] || formattedMenuItemName;;

        dataLayer.triggerEvent('header_click', {
            detail: {
                type: formattedType,
                name: formattedSubItemName,
                link: menuItemLink,
            },
        });
    };

    return (
        <>
            <div
                id="mobile-navigation"
                className="tw-absolute tw-top-16 tw-bg-dark-navy tw-text-white tw-z-50 tw-w-full tw-left-0 tw-font-sst-light"
                style={{ zIndex: 100 }}
            >
                <div className="tw-container tw-px-12 tw-mx-auto tw-text-white tw-relative">
                    <ul ref={menuListRef} className="font-sst-regular">
                        {!menu?.topLevel && (
                            <li>
                                <div className="tw-my-5">
                                    <button
                                        className="mb-2 tw-relative tw-text-left"
                                        onClick={e => {
                                            if (menu.level < 2) {
                                                goBack(true);
                                            } else {
                                                goBack();
                                            }
                                        }}
                                    >
                                        <span
                                            className="tw-transform tw--rotate-180 tw-absolute"
                                            style={{
                                                left: '-30px',
                                                bottom: '3px',
                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                width={20}
                                                height={20}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                                />
                                            </svg>
                                        </span>

                                        <span>{menu && menu.name}</span>
                                    </button>
                                    <hr className="tw-border-light-blue tw-border-2" />
                                </div>
                            </li>
                        )}
                        {menu.menuItems?.map((navItem, i) => {
                            return (
                                <li key={i}>
                                    {navItem.menuItems.length ? (
                                        <button
                                            className="tw-flex tw-justify-between tw-my-7 tw-w-full tw-text-left"
                                            onClick={e => {
                                                changeMenu(navItem);
                                                menu.topLevel && setSelectedCategory(navItem.name);
                                                handleItemClick(
                                                    e,
                                                    menu.topLevel ? navItem.name : selectedCategory,
                                                    !menu.topLevel ? navItem.name : '',
                                                    navItem.link
                                                );
                                            }}
                                        >
                                            <span>{navItem.name}</span>
                                            <span
                                                className="tw-relative"
                                                style={{
                                                    right: '-30px',
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    width={20}
                                                    height={20}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                                    />
                                                </svg>
                                            </span>
                                        </button>
                                    ) : (
                                        <div className="tw-flex tw-justify-between tw-my-5 tw-w-full">
                                            <ProLink
                                                onClick={e =>
                                                    handleItemClick(e, selectedCategory, navItem.name, navItem.link)
                                                }
                                                to={navItem.link}
                                                style={{ color: '#fff' }}
                                            >
                                                {navItem.name}
                                            </ProLink>
                                        </div>
                                    )}
                                </li>
                            );
                        })}

                        {!menu?.topLevel && menu?.navigationShowViewAll && (
                            <li>
                                <hr className="tw-border-light-blue tw-border-2 tw-mb-5" />

                                <div className="mb-3 font-sst-regular">
                                    <ProLink
                                        to={menu?.link}
                                        style={{ color: '#81dcf7' }}
                                        onClick={e => handleItemClick(e, selectedCategory, 'view-all', menu?.link)}
                                    >
                                        {miniGazelle.label('viewAll')}
                                    </ProLink>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
}
