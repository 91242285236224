import React, { useEffect, useRef, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import ProLink from '../../shared/ProLink/ProLink';
import NavigationLogo from './partials/NavigationLogo/NavigationLogo';
import { currentLocale, dataLayer, miniGazelle, shouldUseGrid } from '../../../helpers';
import './Navigation.scss';
import NavigationMobile from './NavigationMobile';
import Hamburger from './Hamburger/Hamburger';
import LoginRegister from '../../shared/LoginRegister/LoginRegister';
import classNames from 'classnames';
import HeaderSearch from '../../shared/HeaderSearch/HeaderSearch';

export default function Navigation({
    data: {
        structure: { nav },
        user,
    },
}) {
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [showSelectedMenu, setShowSelectedMenu] = useState(false);
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const [showLoginRegister, setShowLoginRegister] = useState(false);
    const [showSearchAhead, setShowSearchAhead] = useState(false);
    const navigationRef = useRef(null);
    const headerSearchRef = useRef(null);
    const headerSearchOpenRef = useRef(null);

    useEffect(() => {
        if (showSearchAhead) {
            setTimeout(() => {
                if (headerSearchRef.current) {
                    const searchInput = headerSearchRef.current.querySelector(`#${searchInputID}`);
                    if (searchInput) {
                        searchInput.focus();
                    }
                }
            }, 100);
        }
    }, [showSearchAhead]);

    const loginRegisterClassNames = classNames({
        container: true,
        'login-register-tooltip': true,
        active: showLoginRegister,
    });

    const searchAheadClassNames = classNames({
        container: true,
        'search-tooltip': true,
        'navbar-right': true,
        active: showSearchAhead,
    });

    const searchInputID = 'navigation-typeahead';

    const openNavItem = (e, navItem) => {
        e.preventDefault();

        closeNavMenu();
        setSelectedMenu(navItem);

        setTimeout(() => {
            const parentListItem = e.target.parentElement;
            parentListItem.classList.add('navigation-open');
            parentListItem.classList.remove('navigation-hidden');
            setShowSelectedMenu(true);
        }, 0);
    };

    const closeNavMenu = e => {
        if (e) e.preventDefault();
        setShowSelectedMenu(false);
        navigationRef.current.querySelectorAll('.navigation-top-level-item').forEach(item => {
            item.classList.remove('navigation-open');
            item.classList.add('navigation-hidden');
        });
    };

    const closeSearchAhead = e => {
        if (e) e.preventDefault();
        setShowSearchAhead(false);
    };

    useEffect(() => {
        const handleMouseLeave = e => {
            closeNavMenu(e);
            navigationRef.current.querySelectorAll('.navigation-top-level-item button').forEach(item => {
                item.blur();
            });
        };

        const handleKeyDown = e => {
            if (e.key === 'Escape' || e.key === 'Backspace') {
                closeNavMenu(e);
                closeSearchAhead(e);
            }
        };

        const navRefCurrent = navigationRef.current;

        if (navRefCurrent) {
            navRefCurrent.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (navRefCurrent) {
                navRefCurrent.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, [selectedMenu]);

    const handleSearchKeyDown = e => {
        if (e.key === 'Escape') {
            closeSearchAhead(e);
            headerSearchOpenRef.current.focus();
        }
    };

    const handleKeyDown = e => {
        if (e.key === 'Escape' || e.key === 'Backspace') {
            closeNavMenu(e);
            closeSearchAhead(e);

            if (selectedMenu) {
                const navItemButton = navigationRef.current.querySelector(`#${selectedMenu.id} button`);
                if (navItemButton) {
                    navItemButton.focus();
                }
            }
        }
    };

    const handleItemClick = (e, menuItemChildName, subItemName, menuItemLink, isViewAllLink) => {
        const formattedMenuItemChildName = menuItemChildName.toLowerCase().replace(/\s+/g, '-');
        const formattedSubItemName = subItemName.toLowerCase().replace(/\s+/g, '-');

        const typeMap = {
            'professional-products': miniGazelle.label('products'),
            solutions: miniGazelle.label('solutions'),
            'where-to-buy': miniGazelle.label('wheretobuy'),
            support: miniGazelle.label('support'),
            'contact-us': miniGazelle.label('contactus'),
            promotions: miniGazelle.label('promotions'),
            'view-all': miniGazelle.label('viewAll'),
        };

        const formattedType = typeMap[formattedMenuItemChildName] || formattedMenuItemChildName;

        dataLayer.triggerEvent('header_click', {
            detail: {
                type: !isViewAllLink ? formattedType : 'view-all',
                name: formattedSubItemName,
                link: menuItemLink,
            },
        });
    };

    const byIndustryOrServicesClasses =
        selectedMenu &&
        selectedMenu.menuItems.some(
            item => shouldUseGrid(item.name, 'byIndustry') || shouldUseGrid(item.name, 'byServices')
        )
            ? ''
            : 'tw-grid tw-grid-cols-4 tw-gap-4';

    return (
        <header id="navigation" ref={navigationRef} data-testid="navigation">
            {showSearchAhead && (
                <div
                    className="dropdown-bg"
                    tabIndex={-1}
                    role="button"
                    onClick={() => {
                        setShowSearchAhead(false);
                    }}
                />
            )}
            {mobileMenuVisible && (
                <NavigationMobile nav={{ topLevel: true, menuItems: nav }} visible={mobileMenuVisible} />
            )}
            <div className={showSearchAhead}>
                <div
                    data-testid="navigation-bar"
                    className="navigation-bar tw-bg-black tw-flex tw-items-center tw-w-full tw-h-[64px]"
                >
                    <div className="navigation-container container tw-w-full tw-mx-auto tw-px-4">
                        <div className="tw-flex tw-justify-between tw-text-white tw-font-sst-regular">
                            <div className="tw-flex tw-items-center">
                                <NavigationLogo />
                                <nav
                                    id="desktop-navigation"
                                    className="tw-px-10 tw-hidden md:tw-block"
                                    data-testid="open-menu"
                                >
                                    <ul className="tw-flex">
                                        {nav.map((navItem, i) => (
                                            <li
                                                id={navItem.id}
                                                key={i}
                                                className="navigation-top-level-item navigation-hidden tw-mx-3 tw-flex tw-items-center tw-transition-all tw-h-16"
                                                data-testid={`${navItem.name}-container`}
                                            >
                                                <button
                                                    onClick={e => openNavItem(e, navItem)}
                                                    href={navItem.link}
                                                    className="tw-text-[14px]"
                                                    data-testid={`${navItem.name}-button`}
                                                >
                                                    {navItem.name}
                                                </button>
                                                <div
                                                    className="navigation-drop"
                                                    onKeyDown={handleKeyDown}
                                                    data-testid={`${navItem.name}-navigation-drop`}
                                                    tabIndex={0}
                                                    role="button"
                                                >
                                                    <div className="tw-bg-dark-navy tw-p-10 tw-text-white tw-shadow-md">
                                                        <div className={byIndustryOrServicesClasses}>
                                                            {selectedMenu &&
                                                                selectedMenu.menuItems.map((menuItem, ii) => {
                                                                    const checkCategory =
                                                                        shouldUseGrid(menuItem.name, 'byIndustry') ||
                                                                        shouldUseGrid(menuItem.name, 'byServices');
                                                                    return (
                                                                        <Fragment key={ii}>
                                                                            {checkCategory ? (
                                                                                <div className="tw-mb-5 tw-px-1 tw-font-sst-light">
                                                                                    <hr className="tw-border-light-blue tw-border-1 tw-mb-5" />
                                                                                    <p className="tw-text-light-blue">
                                                                                        {miniGazelle.label(
                                                                                            menuItem.name
                                                                                        )}
                                                                                    </p>
                                                                                    <div className="tw-grid tw-grid-cols-4 tw-gap-4">
                                                                                        {menuItem.menuItems.map(
                                                                                            (menuItemChild, jj) => (
                                                                                                <div
                                                                                                    key={jj}
                                                                                                    data-testid="menu-item-child"
                                                                                                    className="tw-mb-5 tw-px-1 tw-font-sst-light"
                                                                                                >
                                                                                                    <img
                                                                                                        className="tw-w-14"
                                                                                                        alt="category icon"
                                                                                                        role="presentation"
                                                                                                        src={
                                                                                                            menuItemChild
                                                                                                                .iconImage
                                                                                                                .svg ??
                                                                                                            menuItemChild
                                                                                                                .iconImage
                                                                                                                .png
                                                                                                        }
                                                                                                    />
                                                                                                    <a
                                                                                                        onClick={e =>
                                                                                                            handleItemClick(
                                                                                                                e,
                                                                                                                navItem.name,
                                                                                                                menuItemChild.name,
                                                                                                                menuItemChild.link
                                                                                                            )
                                                                                                        }
                                                                                                        href={
                                                                                                            menuItemChild.link
                                                                                                        }
                                                                                                        className="tw-my-3 parent tw-text-light-blue"
                                                                                                    >
                                                                                                        {
                                                                                                            menuItemChild.name
                                                                                                        }
                                                                                                    </a>
                                                                                                    <ul>
                                                                                                        {menuItemChild.menuItems.map(
                                                                                                            (
                                                                                                                subMenuItem,
                                                                                                                jjj
                                                                                                            ) => (
                                                                                                                <li
                                                                                                                    key={
                                                                                                                        jjj
                                                                                                                    }
                                                                                                                >
                                                                                                                    <a
                                                                                                                        onClick={e =>
                                                                                                                            handleItemClick(
                                                                                                                                e,
                                                                                                                                navItem.name,
                                                                                                                                subMenuItem.name,
                                                                                                                                subMenuItem.link
                                                                                                                            )
                                                                                                                        }
                                                                                                                        href={
                                                                                                                            subMenuItem.link ??
                                                                                                                            '#'
                                                                                                                        }
                                                                                                                        aria-label={
                                                                                                                            subMenuItem.name
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            subMenuItem.name
                                                                                                                        }
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            )
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            )
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="tw-mb-5 tw-px-1 tw-font-sst-light">
                                                                                    <img
                                                                                        className="tw-w-14"
                                                                                        alt="category icon"
                                                                                        role="presentation"
                                                                                        src={
                                                                                            menuItem.iconImage.svg ??
                                                                                            menuItem.iconImage.png
                                                                                        }
                                                                                    />
                                                                                    <a
                                                                                        onClick={e =>
                                                                                            handleItemClick(
                                                                                                e,
                                                                                                navItem.name,
                                                                                                menuItem.name,
                                                                                                menuItem.link
                                                                                            )
                                                                                        }
                                                                                        href={menuItem.link}
                                                                                        className="tw-my-3 parent tw-text-light-blue"
                                                                                    >
                                                                                        {menuItem.name}
                                                                                    </a>
                                                                                    <ul>
                                                                                        {menuItem.menuItems.map(
                                                                                            (subMenuItem, iii) => (
                                                                                                <li key={iii}>
                                                                                                    <a
                                                                                                        onClick={e =>
                                                                                                            handleItemClick(
                                                                                                                e,
                                                                                                                navItem.name,
                                                                                                                subMenuItem.name,
                                                                                                                subMenuItem.link
                                                                                                            )
                                                                                                        }
                                                                                                        data-testid="sub-menu-item"
                                                                                                        href={
                                                                                                            subMenuItem.link ??
                                                                                                            '#'
                                                                                                        }
                                                                                                        aria-label={
                                                                                                            subMenuItem.name
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            subMenuItem.name
                                                                                                        }
                                                                                                    </a>
                                                                                                </li>
                                                                                            )
                                                                                        )}
                                                                                    </ul>
                                                                                </div>
                                                                            )}
                                                                        </Fragment>
                                                                    );
                                                                })}
                                                        </div>
                                                        {selectedMenu && selectedMenu.navigationShowViewAll && (
                                                            <div className="tw-pt-10">
                                                                <hr className="tw-border-light-blue tw-border-1 tw-mb-5" />
                                                                <p>
                                                                    <ProLink
                                                                        onClick={e =>
                                                                            handleItemClick(
                                                                                e,
                                                                                navItem.name,
                                                                                selectedMenu.name,
                                                                                selectedMenu.link,
                                                                                selectedMenu.navigationShowViewAll
                                                                            )
                                                                        }
                                                                        to={selectedMenu.link}
                                                                        style={{ color: '#81dcf7' }}
                                                                    >
                                                                        {miniGazelle.label('viewAll')}
                                                                    </ProLink>
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>

                            {/* right */}
                            <div className="tw-flex tw-items-center tw-relative">
                                {user.signedIn && (
                                    <button
                                        className="mr-3"
                                        onClick={e => {
                                            e.preventDefault();
                                            setShowLoginRegister(!showLoginRegister);
                                        }}
                                        data-testid="sign-in"
                                    >
                                        <span className="ngp-icon ngp-icon-user" data-testid="sign-out">
                                            <span className="sr-only">{miniGazelle.label('account')}</span>
                                        </span>
                                    </button>
                                )}
                                {!showSelectedMenu ? (
                                    <button
                                        data-testid="open-search"
                                        onClick={e => {
                                            e.preventDefault();
                                            setShowSearchAhead(!showSearchAhead);
                                        }}
                                        aria-label="Open search"
                                        ref={headerSearchOpenRef}
                                    >
                                        <svg
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{ width: '25px' }}
                                            className="tw-cursor-pointer"
                                            aria-labelledby="open-search-icon"
                                            role="img"
                                        >
                                            <title id="open-search-icon">Open search icon</title>
                                            <path
                                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            ></path>
                                        </svg>
                                    </button>
                                ) : (
                                    <button
                                        onClick={closeNavMenu}
                                        className="hover:tw-rotate-90 tw-transform tw-duration-700"
                                        alt="Open main menu"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            width={30}
                                            height={30}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                )}
                                <div className="tw-flex md:tw-hidden tw-ml-5" data-testid="hamburger">
                                    <Hamburger setIsOpen={setMobileMenuVisible} isOpen={mobileMenuVisible} />
                                </div>
                            </div>
                        </div>
                        <LoginRegister
                            loginRegisterClassNames={loginRegisterClassNames}
                            locale={currentLocale.get()}
                            user={user}
                        />
                        <div ref={headerSearchRef} data-testid="header-search">
                            <HeaderSearch
                                searchAheadClassNames={searchAheadClassNames}
                                locale={currentLocale.get()}
                                searchInputID={searchInputID}
                                onKeyDown={handleSearchKeyDown}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

Navigation.propTypes = {
    data: PropTypes.shape({
        structure: PropTypes.shape({
            nav: PropTypes.array.isRequired,
        }).isRequired,
        user: PropTypes.shape({
            signedIn: PropTypes.bool,
            data: PropTypes.shape({
                name: PropTypes.string,
                email: PropTypes.string,
                updateDetails: PropTypes.string,
            }),
        }).isRequired,
    }).isRequired,
};
